/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import SocialIcons from "./social-media-icons"
import Logo from "../components/logo"
import FooterData from "../util/footer.json"
import FooterMenu from "../util/footerMenu.json"
import siteLayout from "../util/siteLayout.json"

const footerStyles = {
  siteFooter: {
    maxWidth: "100%",
    bg: "footer.footerBgColor",
    p: 0,
    pt: 5,
  },
  footerContainer: {
    maxWidth: siteLayout.footerLayout,
    m: "0 auto",
    display: "grid",
    gridTemplateColumns: ["repeat(1, 1fr)", "repeat(1, 1fr)", "1fr 1fr"],
    px: ["20px", "20px", "40px"],
    gap: ["40px", "40px", "30px"],
    justifyContent: "space-between",
    alignItems: "center",
  },
  footerContainer2: {
    maxWidth: siteLayout.footerLayout,
    m: "0 auto",
    px: ["20px", "20px", "40px"],
    display: ["block", "flex"],
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
    ".menuLinks": {
      textAlign: ["center", "left"],
      mb: [4, 0],
    },
  },
  menuLinks: {
    textAlign: ["center", "center", "left"],
    ul: {
      pl: 0,
      m: 0,
      mt: 2,
    },
    "ul li": {
      display: "inline-block",
      fontSize: 2,
      mr: [4],
    },
    "ul li a": {
      color: "footer.linkColor",
      "&:hover": {
        color: "footer.linkHoverColor",
      },
    },
  },
  copyrightText: {
    fontSize: 0,
    m: 0,
    mt: 5,
    p: 4,
    textAlign: "center",
    bg: "footer.copyrightBgColor",
    color: "footer.copyrightTextColor",
    a: {
      color: "footer.copyrightLinkColor",
      "&:hover": {
        color: "footer.copyrightLinkHoverColor",
      },
    },
  },
}

const menuLink = FooterMenu.footerLinks
const link = menuLink.map((links, index) => {
  return (
    <li key={"fLinks" + index}>
      <a href={links.url} rel="noreferrer">
        {links.title}
      </a>
    </li>
  )
})
const siteLinks = (
  <div sx={footerStyles.menuLinks} className="menuLinks">
    <ul>{link}</ul>
  </div>
)

const Footer = () => {
  return (
    <footer sx={footerStyles.siteFooter}>
      {FooterData.logoVisibility === true ? (
        <div sx={footerStyles.footerContainer}>
          <div>
            <Logo />
            {siteLinks}
          </div>
          <SocialIcons />
        </div>
      ) : (
        <div sx={footerStyles.footerContainer2}>
          {siteLinks}
          <SocialIcons />
        </div>
      )}
      <p sx={footerStyles.copyrightText}>
        {FooterData.copyrightText + " "}
        <span>
          <Link to={FooterData.copyrightTextLink} target="_blank">
            {FooterData.copyrightLinkText}
          </Link>
        </span>
      </p>
    </footer>
  )
}

export default Footer
